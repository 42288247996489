import React, {lazy} from "react";
import {IRoute} from "src/routes/IRoute";

const VillagePage = lazy(() => import("src/pages/experiments/VillagePage"));
const CampPage = lazy(() => import("src/pages/experiments/CampPage"));
const VillagePagePhone = lazy(
    () => import("src/pages/experiments/VillagePagePhone"),
);
const CampPagePhone = lazy(() => import("src/pages/experiments/CampPagePhone"));

const ZombiePage = lazy(() => import("src/pages/experiments/ZombiePage"));
const PhonePage = lazy(() => import("src/pages/experiments/PhonePage"));
const SwampPage = lazy(() => import("src/pages/experiments/SwampPage"));
const ShelterPage = lazy(() => import("src/pages/experiments/ShelterPage"));

export interface IExperimentsRoutes {
    village: IRoute;
    camp: IRoute;
    villagePhone: IRoute;
    campPhone: IRoute;
    zombie: IRoute;
    phone: IRoute;
    swamp: IRoute;
    shelter: IRoute;
}

const experimentsRoutes: IExperimentsRoutes = {
    village: {
        url: () => `/village`,
        page: <VillagePage />,
    },
    camp: {
        url: () => `/camp`,
        page: <CampPage />,
    },
    villagePhone: {
        url: () => `/village-phone`,
        page: <VillagePagePhone />,
    },
    campPhone: {
        url: () => `/camp-phone`,
        page: <CampPagePhone />,
    },

    // новая серия экспериментов
    zombie: {
        url: () => `/landing-zombie`,
        page: <ZombiePage />,
    },
    phone: {
        url: () => `/landing-phone`,
        page: <PhonePage />,
    },
    swamp: {
        url: () => `/landing-swamp`,
        page: <SwampPage />,
    },
    shelter: {
        url: () => `/landing-shelter`,
        page: <ShelterPage />,
    },
};

export default experimentsRoutes;
