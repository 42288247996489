import React, {lazy} from "react";
import {IRoutes} from "src/routes/IRoutes";
import experimentsRoutes from "src/routes/entities/experimentsRoutes";

const MainPage = lazy(() => import("src/pages/MainPage"));

const routes: IRoutes = {
    main: {
        url: () => `/`,
        page: <MainPage />,
    },
    ...experimentsRoutes,
};

export default routes;
