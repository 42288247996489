import React, {Suspense} from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import routes from "src/routes";
import SuspenseLoading from "src/routes/SuspenseLoading";

const AppRouter = () => {
    return (
        <Router>
            <Suspense fallback={<SuspenseLoading />}>
                <Routes>
                    {Object.entries(routes).map(
                        ([key, route]) =>
                            route.page && (
                                <Route
                                    path={route.url(":id")}
                                    key={key}
                                    element={route.page}
                                />
                            ),
                    )}
                </Routes>
            </Suspense>
        </Router>
    );
};

export default AppRouter;
